@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://use.typekit.net/lri2bye.css");

html {
  @apply font-roboto text-[16px] font-normal antialiased text-default scroll-smooth;
}

body {
  @apply font-roboto;
  text-wrap: balance;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  @apply font-bold text-primary mb-4;
}

h1,
.h1 {
  @apply leading-snug text-3xl md:text-4xl lg:text-5xl md:leading-snug lg:leading-snug;
}

h2,
.h2 {
  @apply leading-snug text-3xl;
}

h3,
.h3 {
}

h4,
.h4 {
}

h5,
.h5 {
}

p {
  @apply mb-4;
}

.container {
  @apply max-w-container mx-auto px-5 lg:px-8;
}

.container--full {
  @apply max-w-none;
}

.button {
  @apply uppercase font-bold tracking-wider border border-tertiary-100 block bg-tertiary-100 text-inverse leading-normal py-2 px-4 rounded-md transition-colors hover:bg-tertiary-200 hover:border-tertiary-200;
}

.button--inverse {
  @apply bg-inverse text-tertiary-100 border border-tertiary-100  hover:text-inverse hover:border-tertiary-200;
}

.cog-branding.cog-branding--minimal,
.cog-abuse.cog-wrapper {
  @apply !hidden;
}

.cog-button--submit {
  @apply !mx-auto;
}

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}
